import { Link } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Menu, X ,ChevronDown} from "lucide-react";
import Animation from  "../assets/loading.json"
import Navbar from './navbar'
import Footer from './footer'
import Lottie from "lottie-react";
export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
<div>
<img
  src={require("../assets/DGP+ Grid.png")}
  alt="Background"
  className="fixed inset-0 w-full h-full object-cover z-[-2]"
/>
<div className="inset-0 bg-gradient-to-b fixed object-cover from-black/50 via-black/70 to-black z-[-1]"></div>
<div className="relative h-full w-full">
 

      <Navbar/>
      

      <div className="md:p-10">
    <div className="md:flex   text-white">
    
<div className="mt-10"><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-6xl mt-[80px] m-10">ABOUT US</h1></div>
 <div className=" md:ms-[40%] p-10 mt-20"><img src={require("../assets/logo40.png")} height={300}width={300}></img></div>
</div>
</div><div>
<div className="md:ps-20 md:pe-20 ps-10">
<p style={{fontFamily:"Oswald",letterSpacing:3,fontSize:16,color:"white"}}>
<br/><br/>
DGP+ is a group of companies established in 1984, which provides a one window approach to all your design and construction related needs.

DGP+ provides all-inclusive services including Architecture, Interiors, Landscape, Engineering, Construction, Materials and Property Development.

Our vast experience includes the following building typologies:
<br/><br/>
{/* <Lottie height={40}width={40}  animationData={Animation} /> */}

  </p>
  <ul style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14,color:"white",fontWeight:'bold'}}>
<li className="font-bold">* Hotels & hospitality</li>
<li className="font-bold">* Educational facilities</li>
<li className="font-bold">* Urban planning initiatives</li>
<li className="font-bold">* Restaurants and office interiors</li>
<li className="font-bold">* Health institutions and hospitals</li>
<li className="font-bold">* Industrial buildings and factories</li>
<li className="font-bold">* Residential and commercial projects</li>
<li className="font-bold">* Social institutions and other charities</li>
<li className="font-bold">* Luxury apartment complexes / buildings</li>
<li className="font-bold">* Vacation homes, beach huts and farmhouses</li>
</ul>
</div></div>
      </div>
      <div className="md:p-5 ">

















<div className="md:flex p-1 space-x-1 justify-center text-white ">

<div>
  <img src={require("../assets/about/01.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/about/02.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
</div><div className="md:flex p-1 space-x-1 justify-center text-white ">

<div>
  <img src={require("../assets/about/03.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/about/04.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
</div>
<div className="md:flex p-1 space-x-1 justify-center text-white ">


<div>
  <img src={require("../assets/about/05.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/about/06.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>

</div>

<div className="md:flex p-1 space-x-1 justify-center text-white ">


<div>
  <img src={require("../assets/about/07.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/about/08.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>

</div>

<div className="md:flex p-1 space-x-1 justify-center text-white ">


<div>
  <img src={require("../assets/about/09.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/about/10.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>

</div>








<a href="#home"><div className="fixed right-10 bottom-10 flex justify-center items-center"><i className="fa fa-chevron-up flex justify-center items-center" style={{backgroundColor:"#505050",color:'white',borderRadius:100,height:40,width:40}}></i></div>
</a>


</div>
     <div>
<Footer/>
     </div>
    </div>
  );
}
