import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout";
import ScrollToTop from "./pages/ScrollToTop";
import Home from "./pages/home";
import About from "./pages/about";
import Design from "./pages/design";
import Construction from "./pages/construction";
import Materials from "./pages/materials";
import Properties from "./pages/properties";
import Portfolio from "./pages/portfolio";
import Residential from "./pages/residential";
import Interiors from "./pages/interiors";
import Buildings from "./pages/buildings";
import Bathrooms from "./pages/bathrooms";
import Courtyards from "./pages/courtyards";
import Flooring from "./pages/flooring";
import Gates from "./pages/gates";
import Pools from "./pages/pools";
import Skylights from "./pages/skylights";
import Aghakhan from "./pages/aghakhan";
import Bayview from "./pages/bayview";
import Ladydufferin from "./pages/ladydufferin";
import Olympus from "./pages/olympus";
import Parktower from "./pages/parktower";
import Parkavenue from "./pages/parkavenue";
import Wallfinishes from "./pages/wallfinishes";
import Testkitchen from "./pages/testkitchen";
import Elahee from "./pages/elahee";
import Okra from "./pages/okra";
import Livingfloors from "./pages/livingfloors";
import Testimonials from "./pages/testimonials";
import Contactus from "./pages/contactus";
import Sungi from "./pages/sungi";
import Hubleather from "./pages/hubleather";
import Stairs from "./pages/stairs";
import Commercial from "./pages/commercial";
import Classical from "./pages/classical";
import Designstyles from "./pages/designstyles";
import Vernacular from "./pages/vernacular";
import Elements from "./pages/elements";
import Contemporary from "./pages/contemporary";
import Team from "./pages/team";
import NoPage from "./pages/nopage";
import './App.css';
export default function App() {
  return (
    <BrowserRouter>
    <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
         <Route path="team" element={<Team />} />
           <Route path="staircases" element={<Stairs />} />
           <Route path="hubleather" element={<Hubleather />} />
           <Route path="sungi" element={<Sungi />} />
           <Route path="livingfloors" element={<Livingfloors />} />
           <Route path="contactus" element={<Contactus />} />
           <Route path="testimonials" element={<Testimonials />} />
           <Route path="okra" element={<Okra />} />
           <Route path="elahee" element={<Elahee />} />
           <Route path="testkitchen" element={<Testkitchen />} />
           <Route path="wallfinishes" element={<Wallfinishes />} />
           <Route path="parkavenue" element={<Parkavenue />} />
           <Route path="parktower" element={<Parktower />} />
           <Route path="olympus" element={<Olympus />} />
           <Route path="ladydufferin" element={<Ladydufferin />} />
           <Route path="bayview" element={<Bayview />} />
           <Route path="aghakhan" element={<Aghakhan />} />
           <Route path="skylights" element={<Skylights />} />
           <Route path="pools" element={<Pools />} />
           <Route path="gates" element={<Gates />} />
           <Route path="flooring" element={<Flooring />} />
           <Route path="courtyards" element={<Courtyards />} />
           <Route path="bathrooms" element={<Bathrooms />} />
           <Route path="buildings" element={<Buildings />} />
           <Route path="interiors" element={<Interiors />} />
           <Route path="residential" element={<Residential />} />
           <Route path="contemporary" element={<Contemporary />} />
           <Route path="elements" element={<Elements />} />
           <Route path="vernacular" element={<Vernacular />} />
           <Route path="designstyles" element={<Designstyles />} />
           <Route path="classical" element={<Classical />} />
           <Route path="commercial" element={<Commercial />} />
           <Route path="portfolio" element={<Portfolio />} />
           <Route path="properties" element={<Properties />} />
           <Route path="materials" element={<Materials />} />
           <Route path="construction" element={<Construction />} />
           <Route path="design" element={<Design />} />
           <Route path="about" element={<About />} />
           {/*<Route path="sidebars" element={<Sidebars />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="contacts" element={<Contact />} /> */}
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}